<template>
  <div class="index">
    <!--        <div class="bold title">村口头条</div>-->
    <div class="top_image"
         style="position: relative"
         v-if="topInfo">
      <!-- :autoplay="5000" -->
      <van-swipe  indicator-color="white" class="width_full height_full" style="z-index: 0">
        <van-swipe-item  v-for="(item, index) in topInfo " :key="index" >
          
          <img :src="item.url" alt="" class="width_height_full border_radius_common">
          <div class="bannerBG" @click="playClick(item)">
            <img v-if="item.reqUrl" src="@/assets/img/news/play.png" alt="" class="play-img" >
          </div>
          <div v-if="item.value && item.videoTxtShow" class="bold position_absolute top_title">{{item.value}}</div>
        </van-swipe-item>
      </van-swipe>

    </div>

    <!--        乡村资讯-->
    <!-- <div class="news_header">
      <div class="news_header_title width_full">乡村热点资讯
        <div class="news_header_title_underLine"></div>
      </div>
    </div> -->

    <van-pull-refresh v-model="isLoading"
                      @refresh="onRefresh">
      <div style="padding-bottom: 55px;">
        <van-list :error.sync="error"
                  error-text="请求失败，点击重新加载"
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                  ref="scrollNews">
          <div v-for="(item,index) in infoList"
               :key="index">
            <ruralInformationPicOne v-if="item.infoTemplate == 1"
                                    :info="item"
                                    @click.native="clickNews(item.id)"></ruralInformationPicOne>
            <ruralInformationPicThree v-if="item.infoTemplate == 3"
                                      :info="item"
                                      @click.native="clickNews(item.id)"></ruralInformationPicThree>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>

    <van-tabbar  route
                active-color="#3F7C53"
                inactive-color="#B5BBC9"
                :safe-area-inset-bottom="false">
      <van-tabbar-item replace
                       to="/main/homePage">
        首页
        <template #icon="props">
          <img :src="props.active ? homeIcon.active : homeIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace
                       to="/News/newsHome">
        村口头条
        <template #icon="props">
          <img :src="props.active ? newsIcon.active : newsIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace
                       to="/main/mine">
        我的
        <template #icon="props">
          <img :src="props.active ? mineIcon.active : mineIcon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

import ruralInformationPicOne from "./ruralInformationSubviews/ruralInformationPicOne";
import ruralInformationPicThree from "./ruralInformationSubviews/ruralInformationPicThree";
import { getInformationListForH5, newsImgBaseUrl, getPageElementBeatifulVillage, getHomeQuickDoorUrl } from "../../utils/api";
import common from "../../utils/common";
import { Toast } from "vant";
import {uwStatisticAction} from "../../utils/util";

export default {
  name: "newsHome",
  components: { ruralInformationPicOne, ruralInformationPicThree },
  data () {
    return {
      topInfo: {},
      infoList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: '10',
      error: false,
      listHeight: null,
      homeIcon: {
        active: require('@/assets/img/tabBar/home_1.png'),
        inactive: require('@/assets/img/tabBar/home_0.png'),
      },
      newsIcon: {
        active: require('@/assets/img/tabBar/news_1.png'),
        inactive: require('@/assets/img/tabBar/news_0.png'),
      },
      mineIcon: {
        active: require('@/assets/img/tabBar/mine_1.png'),
        inactive: require('@/assets/img/tabBar/mine_0.png'),
      },
      _dom:'',
      flag: false,
    }
  },
  activated () {
    window.addEventListener("scroll", this.handleScroll, true);
    if (common.getItem('newsHomeScrollTop') != undefined) {
      this.$refs.scrollNews.scroller.scrollTop = common.getItem('newsHomeScrollTop') > 100 ? common.getItem('newsHomeScrollTop') : 0
    }
  },
  deactivated () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  mounted () {
    localStorage.removeItem('newsHomeScrollTop')
    this.toGetPageElementBeatifulVillage()
    uwStatisticAction('/newsHome','乡村新闻列表')

  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    playClick(item) {
      // window.location.href = item.reqUrl
      this.$router.push({name: 'videopage', query:{path: item.reqUrl, poster: item.url, title: item.value}})
    },
    toRechargePayment () {
      getHomeQuickDoorUrl({}).then(res => {
        // 加载状态结束
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            if (res.data.ofpayUrl != undefined) {
              window.location.href = res.data.ofpayUrl
              // this.$router.push({name:'homeQuickDoor',query:{reqUrl:res.data.ofpayUrl}})
            }
          }
        }
      }).catch(() => {
      })
    },
    handleScroll () {
      this.$nextTick(() => {
        if (this.$refs.scrollNews.scroller.scrollTop != undefined && this.$refs.scrollNews.scroller.scrollTop != 0) {
          common.setItem('newsHomeScrollTop', this.$refs.scrollNews.scroller.scrollTop)
        }
      })
    },
    toGetPageElementBeatifulVillage () {
      getPageElementBeatifulVillage().then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.topInfo = res.data.data.topBanner;
            this.topInfo.forEach(item =>{
              item.videoTxtShow = true
            })
          }
        }
      }).catch(() => {
      })
    },
    clickNews (newsId) {
      console.log('clickNews')
      this.$router.push({ name: 'newsDeatail', query: { 'newsId': newsId } })
    },
    toGetInformationListForH5 () {
      getInformationListForH5({
        'pageIndex': this.pageIndex.toString(),
        'pageSize': this.pageSize
      }).then(res => {
        // 加载状态结束
        this.loading = false;
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.finished = !res.data.data.hasNextPage;
            if (this.pageIndex == 1) {
              this.infoList = res.data.data.list;
            } else {
              this.infoList = this.infoList.concat(res.data.data.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
          } else {
            this.error = true;
          }
        } else {
          this.error = true;
        }
      }).catch(() => {
        this.error = true;
      })
    },
    onLoad () {
      this.toGetInformationListForH5();
    },
    onRefresh () {
      this.infoList = [];
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.pageIndex = 1;
      this.onLoad();
    }

  }
}
</script>

<style lang="less" >
.index{
  .van-tab{

  }
}

</style>

<style lang="less" scoped>
  .van-tabbar--fixed{
    position: absolute;
    z-index: 100;
    height: 50px;
  }
.index {
  background: white;
  font-size: 0.3rem;
  .title {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 0.2rem;
    font-size: 22px;
  }
  .bannerBG{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.4) 51%, rgba(0, 0, 0, 0) 100%);
    .play-img{
      width: 1.08rem;
      height: 1.08rem;
    }
  }
  .top_image {
    width: 100%;
    // height: 3.6rem;
    background-color: #eeeeee;
    margin-bottom: 0.4rem;
    .top_title {
      z-index: 11;
      height: 28px;
      left: 0.2rem;
      bottom: 16px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  
  .quick_door {
    margin: 0 0.2rem 12px 0.2rem;
    background-color: white;
    height: 2rem;
    border-radius: 5px;
    .quick_door_item {
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      justify-content: center;
      .quick_door_item_icon {
        width: 44px;
        height: 44px;
      }
    }
    .quick_door_title {
      font-size: 13px;
    }
  }
  .news_header {
    height: 25px;
    line-height: 25px;
    width: 110px;
    margin-left: 0.2rem;
    margin-top: 20px;
    .news_header_title {
      font-weight: bold;
      font-size: 18px;
      .news_header_title_underLine {
        background-color: rgba(52, 161, 89, 0.36);
        width: 100%;
        height: 8px;
        border-radius: 4px;
        margin-top: -10px;
        margin-left: 0%;
      }
    }
  }
}
</style>
